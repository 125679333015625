<template>
  <b-modal
    id="modal-tambahan"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <ModalHeader
        v-if="step === 1"
        :left-text="'Kembali'"
        :middle-text="'Pilih Tambahan'"
        :is-rigt-column="true"
        :right-text="'Selanjutnya'"
        :right-function="nextSubmit"
        :left-function="prevPage"
        :isDisabled="isNextButtonDisabled"
      />
      <ModalHeader
        v-if="step === 2"
        :left-text="'Kembali'"
        :middle-text="
          selectedId === 'add_user'
            ? 'Tambah Pengguna'
            : selectedId === 'add_branch'
            ? 'Tambah Cabang'
            : selectedId === 'extend_user'
            ? 'Perpanjang masa berlaku pengguna'
            : 'Perpanjang masa berlaku cabang'
        "
        :is-rigt-column="true"
        :right-text="
          selectedId === 'add_user' || selectedId === 'add_branch'
            ? 'Bayar'
            : 'Selanjutnya'
        "
        :right-function="
          selectedId === 'add_user' || selectedId === 'add_branch'
            ? createSubscription
            : nextSubmit
        "
        :left-function="prevPage"
        :isDisabled="isNextButtonDisabled"
      />
      <ModalHeader
        v-else-if="step === 3"
        :left-text="'Kembali'"
        :middle-text="'Pilih Metode Pembayaran'"
        :is-rigt-column="true"
        :right-text="'Bayar'"
        :right-function="createSubscription"
        :left-function="prevPage"
        :isDisabled="isPaymentSelected"
      />
      <!-- Spinner -->
      <b-col
        v-if="isLoading == true"
        class="d-flex justify-content-center mt-5 pt-5"
      >
        <b-spinner style="width: 3rem; height: 3rem" label="Loading..." />
      </b-col>
      <b-container v-else class="mt-15">
        <!-- Step 1 -->
        <b-container v-if="step === 1">
          <b-row class="justify-content-center mb-2">
            <b-col cols="12" lg="3" md="6">
              <b-card
                class="border-12 mb-0"
                :class="selectedId === 'add_user' ? 'selected' : ''"
                style="height: 85px; cursor: pointer"
                @click="selectSubscription('add_user')"
              >
                <div class="d-flex align-items-center h-100" style="gap: 14px">
                  <img
                    src="@/assets/images/icons/add-user.svg"
                    alt=""
                    style="width: 46px; height: 46px"
                  />
                  <div class="d-flex flex-column">
                    <h5 class="text-dark size14 fw-bold-800 mb-0">
                      Tambah Pengguna
                    </h5>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" lg="3" md="6">
              <b-card
                class="border-12 mb-0"
                :class="selectedId === 'add_branch' ? 'selected' : ''"
                style="height: 85px; cursor: pointer"
                @click="selectSubscription('add_branch')"
              >
                <div class="d-flex align-items-center h-100" style="gap: 14px">
                  <img
                    src="@/assets/images/icons/add-cabang.svg"
                    alt=""
                    style="width: 46px; height: 46px"
                  />
                  <div class="d-flex flex-column">
                    <h5 class="text-dark size14 fw-bold-800 mb-0">
                      Tambah Cabang
                    </h5>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mb-2">
            <b-col cols="12" lg="3" md="6">
              <b-card
                class="border-12 mb-0"
                :class="selectedId === 'extend_user' ? 'selected' : ''"
                style="height: 85px; cursor: pointer"
                @click="selectSubscription('extend_user')"
              >
                <div class="d-flex align-items-center h-100" style="gap: 14px">
                  <img
                    src="@/assets/images/icons/user-renewal.svg"
                    alt=""
                    style="width: 46px; height: 46px"
                  />
                  <div class="d-flex flex-column">
                    <h5 class="text-dark size14 fw-bold-800 mb-0">
                      Perpanjang Masa Aktif Pengguna
                    </h5>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" lg="3" md="6">
              <b-card
                class="border-12 mb-0"
                :class="selectedId === 'extend_branch' ? 'selected' : ''"
                style="height: 85px; cursor: pointer"
                @click="selectSubscription('extend_branch')"
              >
                <div class="d-flex align-items-center h-100" style="gap: 14px">
                  <img
                    src="@/assets/images/icons/cabang-renewal.svg"
                    alt=""
                    style="width: 46px; height: 46px"
                  />
                  <div class="d-flex flex-column">
                    <h5 class="text-dark size14 fw-bold-800 mb-0">
                      Perpanjang Cabang
                    </h5>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-if="step === 2">
          <template v-if="selectedId === 'add_user'">
            <b-row
              class="flex-column justify-content-center align-items-center pb-2"
            >
              <b-col cols="12" lg="3" md="6">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Periode Akses</label>
                  <v-select
                    v-model="formPayload.duration_month"
                    label="name"
                    :options="durationMonth"
                    placeholder="Periode Akses"
                    :reduce="(duration) => duration.value"
                    @input="getPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="3" md="6">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Jumlah User</label>
                  <div
                    id="tour-qty"
                    class="spinner__button spinner__button--addproduct"
                  >
                    <button
                      id="decrease"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Decrease Value"
                      @click="decreaseValue"
                      :disabled="isDisabled"
                    >
                      -
                    </button>
                    <input
                      id="qty"
                      class="qty text-dark font-weight-bolder"
                      type="text"
                      :value="formPayload.amount"
                      disabled
                    />
                    <button
                      id="increase"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Increase Value"
                      @click="increaseValue"
                      :disabled="isDisabled"
                    >
                      +
                    </button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="3">
                <b-form-group class="custom__form--input">
                  <label for="v-amount">Total Bayar</label>
                  <b-input-group prepend="Rp">
                    <b-form-input
                      id="v-amount"
                      v-model="formPayload.total"
                      class="custom__input"
                      type="text"
                      @input="getRupiah"
                      @change="getRupiah"
                      required
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="pb-2">
              <p class="text-center font-weight-bolder">Metode Pembayaran</p>
              <b-row class="justify-content-center bg-white">
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('bca_va')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="bca_va"
                          value="bca_va"
                        />
                        <b-img
                          style="width: 43px"
                          :src="require('/src/assets/images/logo/logo-bca.png')"
                          alt="bca"
                        />
                      </div>
                      <p class="mb-0 size12">BCA Virtual Account</p>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('mandiri_va')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="mandiri_va"
                          value="mandiri_va"
                        />
                        <b-img
                          style="width: 43px"
                          :src="
                            require('/src/assets/images/logo/logo-mandiri.png')
                          "
                          alt="mandiri"
                        />
                      </div>
                      <p class="mb-0 size12">Mandiri Virtual Account</p>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('qr_code')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="qr_code"
                          value="qr_code"
                        />
                        <b-img
                          style="width: 43px"
                          :src="
                            require('/src/assets/images/logo/logo-qris.png')
                          "
                          alt="qris"
                        />
                      </div>
                      <p class="mb-0 size12">QRIS</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-if="selectedId === 'add_branch'">
            <b-row
              class="flex-column justify-content-center align-items-center pb-2"
            >
              <b-col cols="12" lg="3" md="6">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Periode Akses</label>
                  <v-select
                    v-model="formPayload.duration_month"
                    label="name"
                    :options="durationMonthBranch"
                    placeholder="Periode Akses"
                    :reduce="(duration) => duration.value"
                    @input="getPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="3" md="6">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Jumlah Cabang</label>
                  <div
                    id="tour-qty"
                    class="spinner__button spinner__button--addproduct"
                  >
                    <button
                      id="decrease"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Decrease Value"
                      @click="decreaseValue"
                      :disabled="isDisabled"
                    >
                      -
                    </button>
                    <input
                      id="qty"
                      class="qty text-dark font-weight-bolder"
                      type="text"
                      :value="formPayload.amount"
                    />
                    <button
                      id="increase"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Increase Value"
                      @click="increaseValue"
                      :disabled="isDisabled"
                    >
                      +
                    </button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="3">
                <b-form-group class="custom__form--input">
                  <label for="v-amount">Total Bayar</label>
                  <b-input-group prepend="Rp">
                    <b-form-input
                      id="v-amount"
                      v-model="formPayload.total"
                      class="custom__input"
                      type="text"
                      @input="getRupiah"
                      @change="getRupiah"
                      required
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="pb-2">
              <p class="text-center font-weight-bolder">Metode Pembayaran</p>
              <b-row class="justify-content-center bg-white">
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('bca_va')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="bca_va"
                          value="bca_va"
                        />
                        <b-img
                          style="width: 43px"
                          :src="require('/src/assets/images/logo/logo-bca.png')"
                          alt="bca"
                        />
                      </div>
                      <p class="mb-0 size12">BCA Virtual Account</p>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('mandiri_va')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="mandiri_va"
                          value="mandiri_va"
                        />
                        <b-img
                          style="width: 43px"
                          :src="
                            require('/src/assets/images/logo/logo-mandiri.png')
                          "
                          alt="mandiri"
                        />
                      </div>
                      <p class="mb-0 size12">Mandiri Virtual Account</p>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  lg="3"
                  class="d-flex justify-content-center mb-3"
                >
                  <div
                    class="border cursor-pointer p-2 border-black"
                    style="border-radius: 16px; width: 283px"
                    @click="selectPayment('qr_code')"
                  >
                    <div class="d-flex align-items-center" style="gap: 16px">
                      <div class="d-flex align-items-center">
                        <b-form-radio
                          v-model="selectedPayment"
                          name="qr_code"
                          value="qr_code"
                        />
                        <b-img
                          style="width: 43px"
                          :src="
                            require('/src/assets/images/logo/logo-qris.png')
                          "
                          alt="qris"
                        />
                      </div>
                      <p class="mb-0 size12">QRIS</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-if="selectedId === 'extend_user'">
            <b-row
              class="align-items-center pb-3"
              style="justify-content: space-evenly"
            >
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Periode Akses</label>
                  <v-select
                    v-model="formPayload.duration_month"
                    label="name"
                    :options="durationMonth"
                    :reduce="(duration) => duration.value"
                    placeholder="Periode Akses"
                    @input="getPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Jumlah User</label>
                  <div
                    id="tour-qty"
                    class="spinner__button spinner__button--addproduct"
                  >
                    <button
                      id="decrease"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Decrease Value"
                      @click="decreaseValue"
                      :disabled="isDisabled"
                    >
                      -
                    </button>
                    <input
                      id="qty"
                      class="qty text-dark font-weight-bolder"
                      type="text"
                      :value="formPayload.amount"
                      @change="addInput"
                      disabled
                    />
                    <button
                      id="increase"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Increase Value"
                      @click="increaseValue"
                      :disabled="
                        isDisabled || formPayload.amount === userList.length
                      "
                    >
                      +
                    </button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-amount">Total Bayar</label>
                  <b-input-group prepend="Rp">
                    <b-form-input
                      id="v-amount"
                      v-model="formPayload.total"
                      class="custom__input"
                      type="text"
                      @input="getRupiah"
                      @change="getRupiah"
                      required
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mx-lg-5">
              <b-row
                class="align-items-center justify-content-center border-bottom mb-2"
              >
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">
                    Pilih Admin
                  </h4>
                </b-col>
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">Nomor HP</h4>
                </b-col>
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">
                    Masa Berlaku Terakhir
                  </h4>
                </b-col>
              </b-row>
              <b-row
                class="align-items-center justify-content-center border-bottom mb-2 pb-2"
                v-for="(user, index) in inputsItem"
                :key="index"
              >
                <b-col cols="12" lg="4">
                  <v-select
                    label="name"
                    v-model="user.uuid"
                    :options="userList"
                    :reduce="(user) => user.uuid"
                    placeholder="Jumlah User"
                    @input="handleSelectChange(index, 'user')"
                  />
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group class="custom__form--input mb-0">
                    <b-input-group>
                      <b-form-input
                        v-model="user.phone"
                        class="custom__input"
                        type="number"
                        disabled
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group class="custom__form--input mb-0">
                    <b-input-group>
                      <b-form-input
                        v-model="user.renewal_end_date"
                        class="custom__input"
                        type="text"
                        disabled
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-if="selectedId === 'extend_branch'">
            <b-row
              class="align-items-center pb-3"
              style="justify-content: space-evenly"
            >
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Periode Akses</label>
                  <v-select
                    v-model="formPayload.duration_month"
                    label="name"
                    :options="durationMonthBranch"
                    :reduce="(duration) => duration.value"
                    placeholder="Periode Akses"
                    @input="getPrice"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-name">Jumlah Cabang</label>
                  <div
                    id="tour-qty"
                    class="spinner__button spinner__button--addproduct"
                  >
                    <button
                      id="decrease"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Decrease Value"
                      @click="decreaseValue"
                      :disabled="isDisabled"
                    >
                      -
                    </button>
                    <input
                      id="qty"
                      class="qty text-dark font-weight-bolder"
                      type="text"
                      :value="formPayload.amount"
                      @change="addInput"
                      disabled
                    />
                    <button
                      id="increase"
                      class="btn value-button text-dark font-weight-bolder"
                      value="Increase Value"
                      @click="increaseValue"
                      :disabled="
                        isDisabled || formPayload.amount === branchList.length
                      "
                    >
                      +
                    </button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="2">
                <b-form-group class="custom__form--input">
                  <label for="v-amount">Total Bayar</label>
                  <b-input-group prepend="Rp">
                    <b-form-input
                      id="v-amount"
                      v-model="formPayload.total"
                      class="custom__input"
                      type="text"
                      @input="getRupiah"
                      @change="getRupiah"
                      required
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mx-lg-5">
              <b-row
                class="align-items-center justify-content-center border-bottom mb-2"
              >
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">
                    Pilih Cabang
                  </h4>
                </b-col>
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">Nomor HP</h4>
                </b-col>
                <b-col cols="12" lg="4">
                  <h4 class="mb-0 size14 font-weight-bolder pb-2">
                    Masa Berlaku Terakhir
                  </h4>
                </b-col>
              </b-row>
              <b-row
                class="align-items-center justify-content-center border-bottom mb-2 pb-2"
                v-for="(branch, index) in inputsItem"
                :key="index"
              >
                <b-col cols="12" lg="4">
                  <v-select
                    label="name"
                    v-model="branch.uuid"
                    :options="branchList"
                    :reduce="(branch) => branch.uuid"
                    placeholder="Select branch"
                    @input="handleSelectChange(index, 'branch')"
                  />
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group class="custom__form--input mb-0">
                    <b-input-group>
                      <b-form-input
                        v-model="branch.phone"
                        class="custom__input"
                        type="number"
                        disabled
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group class="custom__form--input mb-0">
                    <b-input-group>
                      <b-form-input
                        v-model="branch.renewal_end_date"
                        class="custom__input"
                        type="text"
                        disabled
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-container>
        <!-- Step 2 -->
        <b-container v-if="step === 3">
          <b-row class="justify-content-center bg-white pb-2">
            <b-col
              cols="12"
              md="6"
              lg="3"
              class="d-flex justify-content-center mb-3"
            >
              <b-card
                class="w-75 cursor-pointer"
                @click="selectPayment('bca_va')"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-form-radio
                    v-model="selectedPayment"
                    name="bca_va"
                    value="bca_va"
                  />
                  <b-img
                    :src="require('/src/assets/images/logo/logo-bca.png')"
                    alt="bca"
                  />
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="3"
              class="d-flex justify-content-center mb-3"
            >
              <b-card
                class="w-75 cursor-pointer"
                @click="selectPayment('mandiri_va')"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-form-radio
                    v-model="selectedPayment"
                    name="mandiri_va"
                    value="mandiri_va"
                  />
                  <b-img
                    :src="require('/src/assets/images/logo/logo-mandiri.png')"
                    alt="mandiri"
                  />
                </div>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="3"
              class="d-flex justify-content-center mb-3"
            >
              <b-card
                class="w-75 cursor-pointer"
                @click="selectPayment('qr_code')"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-form-radio
                    v-model="selectedPayment"
                    name="qr_code"
                    value="qr_code"
                  />
                  <b-img
                    :src="require('/src/assets/images/logo/logo-qris.png')"
                    alt="qris"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import ModalHeader from "@/components/ModalHeader.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vSelect,
    BImg,
    BCard,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BContainer,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    ModalHeader,
    BFormSelect,
  },
  data() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const renewalMonths =
      userData && userData.branch
        ? userData.branch.remaining_renewal_months
        : 0;

    return {
      step: 1,
      subscription: [],
      selectedId: "",
      selectedPayment: "",
      formPayload: {
        subscription_type: "",
        duration_month: "",
        amount: 1,
        total: "",
        payment_provider_name: "xendit",
        payment_method: "",
      },
      minValue: 0,
      maxValue: 100,
      doubleValue: 2,
      durationMonthBranch: [
        {
          name: "1 Bulan",
          value: 1,
        },
        {
          name: "3 Bulan",
          value: 3,
        },
        {
          name: "6 Bulan",
          value: 6,
        },
        {
          name: "12 Bulan",
          value: 12,
        },
      ],
      durationMonth: [
        {
          name: "3 Bulan",
          value: 3,
        },
        {
          name: "6 Bulan",
          value: 6,
        },
        {
          name: "12 Bulan",
          value: 12,
        },
        {
          name: "Ikut Masa Aktif Cabang",
          value: renewalMonths,
        },
      ],
      amountList: [
        {
          text: "2 User",
          value: 2,
        },
        {
          text: "4 User",
          value: 4,
        },
        {
          text: "6 User",
          value: 6,
        },
        {
          text: "8 User",
          value: 8,
        },
        {
          text: "10 User",
          value: 10,
        },
      ],
      inputsItem: [
        {
          uuid: "",
          phone: "",
          renewal_end_date: "",
        },
      ],
      userList: [],
      branchList: [],
      isLoading: false,
      isDisabled: false,
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getUser();
    this.getBranch();
  },
  computed: {
    hasSelectedItem() {
      return this.selectedId !== "";
    },
    isNextButtonDisabled() {
      return !this.hasSelectedItem;
    },
    isPaymentSelected() {
      return !this.selectedPayment;
    },
    highestDiscountSubscription() {
      if (this.subscription.length === 0) {
        return [];
      }

      // Find the subscription with the highest discount_percent
      const highestDiscount = Math.max(
        ...this.subscription.map((item) => item.discount_percent)
      );

      return highestDiscount;
    },
  },
  methods: {
    handleSelectChange(index, name) {
      if (name === "branch") {
        const selectedBranch = this.branchList.find(
          (branch) => branch.uuid === this.inputsItem[index].uuid
        );
        this.$set(this.inputsItem, index, {
          ...this.inputsItem[index],
          phone: selectedBranch.phone,
          renewal_end_date: selectedBranch.renewal_end_date,
        });
      } else {
        const selectedBranch = this.userList.find(
          (branch) => branch.uuid === this.inputsItem[index].uuid
        );
        this.$set(this.inputsItem, index, {
          ...this.inputsItem[index],
          phone: selectedBranch.phone,
          renewal_end_date: selectedBranch.renewal_end_date,
        });
      }
    },
    getPrice() {
      this.isDisabled = true;
      this.$http
        .get(
          `/subscription-price?subscription_type=${this.selectedId}&duration_month=${this.formPayload.duration_month}&amount=${this.formPayload.amount}`
        )
        .then((response) => {
          this.formPayload.total = response.data.data.total_price;
          this.formPayload.total = this.formatRupiah(
            this.formPayload.total.toString()
          );
          this.isDisabled = false;
        })
        .catch((err) => {
          console.log(err);
          this.isDisabled = false;
        });
    },
    async getUser() {
      this.$http
        .get(`/users`)
        .then((response) => {
          this.userList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getBranch() {
      this.$http
        .get(`/merchantBranch`)
        .then((response) => {
          this.branchList = response.data.data.data.filter(
            (data) => data.is_main_branch != true
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addInput() {
      const currentAmount = this.formPayload.amount;
      const additionalInputs = currentAmount - this.inputsItem.length;

      if (additionalInputs > 0) {
        for (let i = 0; i < additionalInputs; i++) {
          this.inputsItem.push({
            uuid: "",
          });
        }
      } else if (additionalInputs < 0) {
        this.inputsItem.splice(currentAmount);
      }
    },
    increaseValue() {
      if (this.formPayload.amount < this.maxValue) {
        this.formPayload.amount++;
        this.addInput();
      }
      this.getPrice();
    },
    decreaseValue() {
      if (this.formPayload.amount > this.minValue) {
        this.formPayload.amount--;
        this.addInput();
      }
      this.getPrice();
    },
    getRupiah() {
      const rupiah = document.getElementById("v-amount");
      rupiah.value = this.formatRupiah(rupiah.value);
      this.formPayload.total = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    createSubscription() {
      if (this.step === 2 || this.step === 3) {
        if (!this.selectedPayment) {
          errorNotification(
            "Silahkan pilih metode pembayaran terlebih dahulu."
          );
          return;
        }
        this.formPayload.subscription_type = this.selectedId;
        this.formPayload.payment_method = this.selectedPayment;
        if (this.selectedId === "extend_user") {
          this.formPayload.users = this.inputsItem;
        } else if (this.selectedId === "extend_branch") {
          this.formPayload.branches = this.inputsItem;
        }

        this.$swal({
          title: "Konfirmasi",
          text: "Apakah Anda yakin?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            const payload = this.formPayload;
            this.$store
              .dispatch("tambahan/postTambahan", { payload })
              .then((response) => {
                this.$bvModal.hide("modal-tambahan");
                const uuid = response.data.data.uuid;

                this.$router.push({
                  name: "billing_tambahan.index",
                  params: { id: uuid },
                });
              })
              .catch((error) => {
                this.isLoading = false;
                errorNotification("Error creating subscription.");
                console.error(error);
              });
          }
        });
      }
    },
    selectSubscription(id) {
      this.selectedId = id;
    },
    selectPayment(paymentMethod) {
      this.selectedPayment = paymentMethod;
    },
    nextSubmit() {
      if (this.step === 1) {
        this.step = 2;
      } else if (this.step === 2) {
        this.step = 3;
      }
    },
    prevPage() {
      if (this.step === 2) {
        this.step = 1;
        this.selectedId = "";
        this.formPayload = {
          duration_month: "",
          amount: 1,
          total: "",
          payment_provider_name: "xendit",
        };
        this.inputsItem = [
          {
            uuid: "",
            phone: "",
            renewal_end_date: "",
          },
        ];
        this.selectedPayment = "";
      } else if (this.step === 3) {
        this.step = 2;
      } else {
        this.$bvModal.hide("modal-tambahan");
      }
    },
    getSelectedSubscription(propertyName) {
      const selectedRenewal = this.subscription.find(
        (item) => item.id === this.selectedId
      );
      return selectedRenewal ? selectedRenewal[propertyName] : "";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.card-body {
  padding: 1rem;
}

.selected {
  box-shadow: 0 10px 10px 0 #52cec2;
}
</style>
