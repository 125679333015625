<template>
  <div class="master-toko">
    <div class="section">
      <header-page>
        <span
          class="size14 fw-bold-700"
          style="color: #0b5fff; margin-right: 5px"
          >Master</span
        >
        <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
        <span class="text-dark fw-bold-400 size14">Billing</span>
      </header-page>

      <div
        class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
        style="height: 82px; background-color: #f6f7fa"
      >
        <b-button-group>
          <!-- <b-button
            class="filter--button"
            :class="{ active: tabFilter == 'toko-utama' }"
            @click="(tabFilter = 'toko-utama'), (comp = 'TableBilling')"
          >
            Toko Utama
          </b-button>
          <b-button
            class="filter--button"
            :class="{ active: tabFilter == 'tambahan' }"
            @click="(tabFilter = 'tambahan'), (comp = 'TableTambahan')"
          >
            Tambahan
          </b-button> -->
          <b-button
            v-for="(item, index) in listFilter"
            :key="`${item}-${index}`"
            class="filter--button"
            :class="{ active: item == filter }"
            @click="
              (filter = item),
                (comp = item == 'Toko Utama' ? 'TableBilling' : 'TableTambahan')
            "
          >
            {{ item }}
          </b-button>
        </b-button-group>
        <!-- <div class="d-flex search__export" style="width: unset">
        <b-button
          v-if="comp === 'TableCustomer'"
          variant="outline-secondary py-0 bg-secondary text-white"
          @click="addData('modal-subscribe')"
        >
          <span>+ Tambah Data</span>
        </b-button>
        <b-button
          v-if="comp === 'TableSupplier'"
          variant="outline-secondary py-0 bg-secondary text-white"
          @click="addData('modal-supplier')"
        >
          <span>+ Tambah Data</span>
        </b-button>
      </div> -->
      </div>

      <keep-alive>
        <component :is="comp" />
      </keep-alive>

      <!-- <TableBilling
      :get-list-subscription="getListSubscription"
      :result="result"
      @filter="getFilter"
    /> -->
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { BContainer, BButtonGroup, BButton } from "bootstrap-vue";
import TableBilling from "@/components/Billing/TableBilling.vue";
import TableTambahan from "@/components/Billing/TableTambahan.vue";

import HeaderPage from "@/components/HeaderPage.vue";

export default {
  title() {
    return "Master Billing";
  },
  components: {
    BContainer,
    BButtonGroup,
    BButton,
    HeaderPage,
    TableBilling,
    TableTambahan,
  },
  data() {
    return {
      // comp: "TableBilling",
      // tabFilter: "Toko Utama",
      listFilter: ["Toko Utama", "Tambahan"],
      filter: "Toko Utama",
      comp: "TableBilling",
    };
  },
  mounted() {
    if (this.$route.query.filter == "tambahan") {
      this.filter = "Tambahan";
      this.comp = "TableTambahan";
    }
  },
  //   watch: {
  //     filter: {
  //       handler: _.debounce(function () {
  //         this.getListSubscription();
  //       }, 300),
  //       deep: true,
  //     },
  //   },

  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.master-toko {
  button.bg-white {
    &:focus {
      background-color: transparent !important;
    }
  }
}
</style>
